export default {
  namespaced: true,
  state: () => ({
    matricula: null,
    asignaturas: [],
  }),
  mutations: {
    SET_MATRICULA(state, value) {
      state.matricula = value;
    },
    SET_ASIGNATURAS(state, value) {
      state.asignaturas = value;
    },
  },
};

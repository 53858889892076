import { defineAsyncComponent } from 'vue';

const MisRecibos = defineAsyncComponent(() => import(/* webpackChunkName: "gestecon-MisRecibosView" */'./views/MisRecibosView.vue'));

const NuevoRecibo = defineAsyncComponent(() => import(/* webpackChunkName: "gestecon-NuevoReciboView" */'./views/NuevoReciboView.vue'));

export default [
  {
    path: '/mis-recibos/',
    name: 'MisRecibos',
    component: MisRecibos,
    meta: {
      requiresAuth: true,
      group: 'gestecon',
      application: 'mis-recibos',
    },
  },
  {
    path: '/nuevo-recibo/',
    name: 'GESTECON-NuevoRecibo',
    component: NuevoRecibo,
    meta: {
      requiresAuth: true,
      group: 'gestecon',
      application: 'mis-recibos',
    },
  },
];

import { defineAsyncComponent } from 'vue';

const ConsultaDeportes = defineAsyncComponent(() => import(/* webpackChunkName: "pci-ConsultaDeportes" */'./views/ConsultaDeportes.vue'));

export default [
  {
    path: '/consulta-deportes/',
    name: 'ConsultaDeportes',
    component: ConsultaDeportes,
    meta: {
      requiresAuth: true,
      group: 'pci',
      application: 'consulta-deportes',
      hiddenFeature: true,
    },
  },
];

export default {
  namespaced: true,
  // ESTADO
  state: {
    reciboSeleccionado: null,
  },
  // MUTACIONES: sólo estas pueden modificar el estado
  mutations: {
    asignarRecibo(state, valor) {
      state.reciboSeleccionado = valor;
    },
  },
};

import { defineAsyncComponent } from 'vue';

const MiTF = defineAsyncComponent(() => import(/* webpackChunkName: "MiTF" */ '@/app/acade/mi-tf/views/MiTF.vue'));

export default [
  {
    path: '/miTF',
    name: 'MiTF',
    component: MiTF,
    meta: {
      requiresAuth: true,
      group: 'acade',
      application: 'mi-tf',
      hiddenFeature: true,
    },
  },
];
